import SvgAnimationBackground from '@Components/Common/SvgAnimationBackground';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useRouter } from 'next/router';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import LoginStyle from 'src/pages/[page]/login/LoginStyle';

const SiteAuth = ({ PassCode, setAccessSite }) => {

    const { register, handleSubmit, errors, setError } = useForm({
        mode: "all"
    });
    const [passwordShow, setPasswordShow] = useState(false);

    function onSubmit(data) {
        if (data?.password === PassCode) {
            localStorage.setItem('access', true);
            setAccessSite(true);
        }
    }
    function showPassword() {
        setPasswordShow(!passwordShow);
    }
    return (
        <>
            <LoginStyle>
                <div className="container">
                    <form className="signupform main-sign-frm" onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="title">Auth</h1>
                        <div className="mb-3 field-class">
                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                            <div className="form-wrap">
                                <input type={passwordShow ? "text" : "password"} className="form-control" name="password" id="exampleInputPassword1" ref={register({ required: true })} />
                                {
                                    passwordShow ?
                                        <Visibility onClick={() => showPassword()} /> : <VisibilityOff onClick={() => showPassword()} />
                                }

                                {errors.password && <span className="error">This field is required</span>}
                            </div>
                        </div>

                        <div className="sign-up-bnt">
                            <button type="submit" className="btn btn-primary login">Check Code</button>
                        </div>
                    </form>
                </div>
            </LoginStyle>
            <SvgAnimationBackground />
        </>
    )
}

export default SiteAuth;