import React from 'react';

const DevBanner = () => {

    return (
        <div style={{
            backgroundColor: '#ffcc00',
            color: '#000',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
            marginTop: '20px'
        }}>
            🚧 You are currently on the Development Site! 🚧
        </div>
    );
};

export default DevBanner;
