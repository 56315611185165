import React from 'react'

export default function SvgAnimationBackground() {
    return (
        <div className="animated-gradients position-fixed">
            <svg className="animated-gradients__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <rect width="100%" height="150%" x="-65%" y="-65%" fill="url(#gradient1a)"></rect>
                <rect width="150%" height="200%" x="10%" y="-80%" fill="url(#gradient1b)"></rect>
                <rect width="190%" height="150%" x="-50%" y="30%" fill="url(#gradient1c)"></rect>
                <defs>
                    <radialGradient id="gradient1a">
                        <stop offset="0%" stopColor="#f1baba">
                            <animate attributeName="stop-color" values="#f1baba;#FFFFFF;#c9bef2;#ffdeaa;#FFFFFF;#ffdeaa;#f1baba" dur="20s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="100%" stopColor="#FFFFFF"></stop>
                    </radialGradient>
                    <radialGradient id="gradient1b">
                        <stop offset="0%" stopColor="#b0e4ec">
                            <animate attributeName="stop-color" values="#b0e4ec;#c9bef2;#FFFFFF;#b0e4ec;#c9bef2;#FFFFFF;#b0e4ec" dur="20s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0"></stop>
                    </radialGradient>
                    <radialGradient id="gradient1c">
                        <stop offset="0%" stopColor="#FFFFFF">
                            <animate attributeName="stop-color" values="#FFFFFF;#ffdeaa;#f1baba;#FFFFFF;#b0e4ec;#f1baba;#FFFFFF" dur="20s" repeatCount="indefinite"></animate>
                        </stop><stop offset="100%" stopColor="#FFFFFF" stopOpacity="0"></stop>
                    </radialGradient>
                </defs>
            </svg>
        </div>
    )
}