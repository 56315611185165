import NumberFormat from 'react-number-format';
import { AiFillDelete } from 'react-icons/ai';
import { IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import _ from 'lodash';
import NumberFormatComp from '@Components/Common/NumberFormatComp';
import { UpdateSmartShip } from '@Components/Common/CommonMethods';

const Product = ({ subscribeAlready, smartShipCheck, minCartAutoshipCheck, setCartData, cartdata, getMiniCartProduct, logintoken, Cart, deleteproduct, index, Add, Sub, updateqty
  , setcoupon, setdiscount_amount, setAmountCalculations }) => {
  //check product is selected to autoship or normal

  const AUTOSHIP_PRICE = Math.floor(+Cart?.product?.autoship_cost_price);
  return (
    <>
      <div className="mini-cart" key={index}>
        <div className="min-cat-uppper">

          <div className="cart-image">
            {Cart?.product?.product_images[0]?.image
              ?
              <img src={`${process.env.API_URL}${Cart?.product.product_images[0]?.image}`} />
              :
              <img src="/images/no-image.jpg" />
            }
          </div>
          <div className="mini-cart-des">
            <div className="cart-product-details">
              {Cart?.product?.name}
            </div>

            {!(subscribeAlready) &&
              <div className="" data-value="Frequency">
                {AUTOSHIP_PRICE !== 0 &&
                  <UpdateSmartShip
                    product_id={+Cart?.product?.id} cartId={+Cart?.id}
                    quantity={Cart?.quantity} defualtAutoship={Cart?.is_autoship}
                    token={logintoken} callbackFunction={(section, logintoken) => getMiniCartProduct(section, logintoken)} setCartData={setCartData}
                    setcoupon={setcoupon}
                    setAmountCalculations={setAmountCalculations}
                    setdiscount_amount={setdiscount_amount}
                  />
                }
              </div>
            }
            <div className="main-qty-sec">
              <div className="box">
                {Cart?.qty_display &&
                  <div id="qty">
                    <button type="button"
                      name={`${Cart?.product?.id},${null}`}
                      disabled={Cart?.quantity === "1"}
                      id={Cart?.product?.id}
                      className="sub"
                      value={Cart?.quantity}
                      onClick={(e) => { Sub(e, Cart?.id, Cart?.is_autoship) }}>-</button>
                    <input
                      name={`${Cart?.product?.id},${null}`}
                      type="text"
                      readOnly
                      value={+(updateqty?.id) === +(Cart?.product?.id) ? +(updateqty.value) : Cart?.quantity}
                    />
                    <button type="button"
                      name={`${Cart?.product?.id},${null}`}
                      id={Cart?.product?.id} data-value={Cart?.product?.quantity}
                      className="add"
                      value={Cart?.quantity}
                      onClick={(e) => { Add(e, Cart?.id, Cart?.is_autoship) }}>+</button>
                  </div>
                }
              </div>
            </div>
            <div className="cart-product-details">
              <div className="title">
                <NumberFormatComp value={Cart?.product?.cost_price * Cart?.quantity}
                  render={value => <>{
                    (Cart?.is_autoship === "False")
                      ?
                      <div>{value}</div>
                      :
                      <div>
                        {`$${parseFloat(Cart?.product?.[`${(Cart?.is_autoship !== "False") ? "autoship_cost_price" :
                          "cost_price"}`] * Cart?.quantity).toFixed(2)}` + " " + (Cart?.is_autoship === "True" ? 'Subscription Price' : "")}
                      </div>
                  }
                  </>
                  }
                />
              </div>
            </div>
          </div>

          <div className="cart-product-qty-del">
            <button><DeleteOutlineIcon id={Cart?.product?.id} data-value={Cart?.variant?.id} onClick={(e) => { deleteproduct(Cart?.product?.id, Cart?.id, Cart?.is_autoship) }} className="dlt" /></button>
          </div>
        </div>
      </div>
    </>)
}
export default Product;