import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import DialogComponent from '@Components/Common/DialogComponent';
import { Button, RefferalContainer, CurrentRefferal, PromoterPackInViewCartPop } from './ViewCartPop.style';
import api from '@api/Apis';
import { useRouter } from 'next/router';
var randomstring = require("randomstring");

const ViewCartPopup = React.forwardRef(({ content }, ref) => {
    const router = useRouter();
    const { open, section, setOpen, setshowminicart, setshowloader } = content;
    const [refferalPopup, setRefferalPopup] = React.useState(false);
    const [designation, setDesignation] = React.useState('');
    const [refferalCode, setRefferalCode] = React.useState({
        value: "",
        error: ""
    });

    const [goToPromoterPack, setGoToPromoterPack] = React.useState(false);
    const [correctRefferal, setCorrectRefferal] = React.useState({
        status: false,
        data: {}
    });

    React.useImperativeHandle(ref, () => ({
        setRefferalPopup: (value) => setRefferalPopup(value),
        setGoToPromoterPack: (value) => setGoToPromoterPack(value),
        setCorrectRefferal: (value) => setCorrectRefferal(value)
    }));
    useEffect(() => {
        router?.query?.d === "is_affiliate"
    }, []);

    const handleSubmit = async () => {
        if (refferalCode?.value) {
            await api.subDomainCheckFunction({ referral_code: refferalCode?.value }).then((res) => {
                if (res?.status === 200) {
                    if (res?.data?.status === true) {
                        const { user_details } = res?.data?.data;
                        setCorrectRefferal({
                            status: res?.data?.status,
                            data: {
                                image: user_details?.image,
                                name: user_details?.name,
                                email: user_details?.email,
                                phone: user_details?.phone
                            }
                        });
                        setGoToPromoterPack(false);
                    } else {
                        setRefferalCode({ ...refferalCode, error: 'This Referrer Code is invalid. Please check with your Referrer and try again.', });
                        setCorrectRefferal({ status: res?.data?.status, data: {} });
                    }
                }
            }).catch((err) => console.log(err))
        } else {
            setRefferalCode({ ...refferalCode, error: 'please enter refferal code', })
        }
    }

    return (
        <DialogComponent opend={open} handleClose={setOpen} titleBanner={<img src="/images/logo_snip.png" />} classFor="view_cart_popup">
            {
                (refferalPopup && !correctRefferal?.status) &&
                <RefferalContainer className="">
                    <h3 className="ref_title"> <img src='/images/welcome.png' /></h3>
                    <h4 className="ref_subtitle">Please enter your referring Affiliate's Referrer Code:</h4>
                    <div className="text-container">
                        <div className="ref_input-form">
                            <input type="text" className="form-control"
                                name="referring_id"
                                placeholder="Referrer code"
                                onChange={(e) => {
                                    setRefferalCode({ value: e.target.value, error: "" });
                                }} />
                        </div>
                        <div className="sk-ref__input-btn">
                            <Button type="button" className="ref_input-button" onClick={() => handleSubmit()}>Submit</Button>
                        </div>
                    </div>

                    <p className="dont_haveCode" onClick={() => {
                        // setGoToPromoterPack(false);
                        // setRefferalPopup(true);
                        // setCorrectRefferal({ status: true, data: [] });
                        if (section === "professional") {
                            window.location.assign('/us/signup?d=is_professional');
                        } else {
                            window.location.assign('/us/signup?d=is_affiliate');
                        }
                    }}>I don't have referrer code</p>

                    <p className="ref_msg_new"><span>Not sure what this means?</span> Send an email with subject line 'Referrer Code' to <a className="ref__mailto" href="mailto:support@snipnutrition.net">support@snipnutrition.net</a></p>
                    <p className="error_msg">{refferalCode?.error ? refferalCode?.error : ""}</p>
                </RefferalContainer>
            }

            {
                (refferalPopup && correctRefferal?.status && !goToPromoterPack) &&
                <CurrentRefferal className="currentRef_body">
                    <h5 className="refby-title">You were referred to SNiP Nutrigenomics by:</h5>
                    <img src={`${process.env.API_URL}${correctRefferal?.data?.image}?id=${randomstring.generate(16)}`} width='60' height='60' />
                    <h4 className="refby-name">{correctRefferal?.data?.name}</h4>
                    <div className="refby-contact">
                        <a href={`tel:${correctRefferal?.data?.phone}`} className="refby-contact-info ">{correctRefferal?.data?.phone}</a>
                        <a href={`mailto:${correctRefferal?.data?.email}`} className="refby-contact-info">{correctRefferal?.data?.email}</a>
                    </div>
                    <div className="refby-btn">
                        <Button className="refby_button" onClick={() => {
                            if (section === "professional") {
                                // setGoToPromoterPack(true);
                                localStorage.setItem("referral_code", refferalCode?.value);
                                localStorage.setItem('correctRefferal', correctRefferal);
                                window.location.assign('/us/signup?d=is_professional');
                            } else {
                                localStorage.setItem("referral_code", refferalCode?.value);
                                localStorage.setItem('correctRefferal', JSON.stringify(correctRefferal));
                                window.location.assign('/us/signup?d=is_affiliate');
                            }
                        }}>Yes, This is my referrer</Button>
                    </div>
                    <button className="ref-confirm-no" onClick={() => setCorrectRefferal({ ...correctRefferal, status: false })}>
                        No, this is not my referrer
                    </button>
                    <p className="refby_note">Please note, your referrer cannot be changed after your account is created.</p>
                </CurrentRefferal>
            }
        </DialogComponent >
    );
});

ViewCartPopup.propTypes = {
    content: PropTypes.object.isRequired,
}
export default ViewCartPopup;

