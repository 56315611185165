import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useState } from "react";
import { DialogActions, DialogTitle } from "@material-ui/core";
import styled from "styled-components";
import api from "@api/Apis";

const DialogStyle = styled.div`

  .MuiDialog-container{
    .MuiPaper-root {
      width: 100%;
    }
  }
  .main_dialog {
    text-align: center;

    .MuiTypography-root {
      font-family: var(--inte-BD);
      font-size: 30px;
      padding-bottom: 10px;
    }
    .MuiDialogContent-root {
      padding-bottom: 0px;

      label {
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;

        a {
          color: var(--green);
        }

        input {
          vertical-align: middle;
          margin-right: 5px;
          margin-top: 0;
        }
      }
    }
    .MuiDialogActions-root {
      justify-content: center;
      padding: 8px 24px 24px;

      .MuiButton-root {
        border-color: var(--green);
        background-color: var(--green);

        &.Mui-disabled {
          background-color: #ccc;
          border-color: #ccc;
          pointer-events: none;
        }
      }

    }
  }
 
`;
export default function DialogPrivacyPolicy({ openPop, setOpenPop }) {
  const [selectedValue, setSelectedValue] = useState("");
  const handleSubmit = () => {
    const formData = {
      privacy_policy: selectedValue
    }
    api.privacyPolicy(formData).then(res => {
      localStorage.removeItem("profileData")
      setOpenPop(false)
    })
  }
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Dialog
      open={openPop}
      className="cstm-popup"
    >
      <DialogStyle>
        <div className="main_dialog">
          <DialogTitle
            id="alert-dialog-title"
          >
            Policies & Procedures
          </DialogTitle>
          <DialogContent className="container_for_load_data">
            <label>
              <input
                type="radio"
                value="true"
                checked={selectedValue === "true"}
                onChange={handleRadioChange}
              />
              <span>
                I have read and agree to the<a className="privacy_style" target='_blank' href='https://admin.snipnutrition.com/media/documents/Snip_Nutrigenomics_P&P_3.8.24.pdf'> Policies & Procedures</a>.
              </span>
            </label>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary" disabled={!selectedValue}>
              Submit
            </Button>
          </DialogActions>
        </div>
      </DialogStyle>
    </Dialog>
  );
}
