import styled from "styled-components";

const ViewCartStyle = styled.div`

.view-data-table {
  .viewcart-table-data {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #000;
    border-radius: 20px;
  }
}
  & .main_viewCart_section_class {
    height: 100%;
    &.cart-sec {  
      h1 { 
        font-size: 26px;
        text-transform: uppercase;
        font-family: var(--inte-BD);
        color: var(--green)
      }
    }
    .table_container {
      height: 100%;
      max-height: 950px;
      overflow-y: auto;
      .whistlist-table {
        width: 100%; 
        height: 45px; 
        background: #fff;
        padding: 0 10px;
        tr {
          &.checkout-btn + tr {
            background: #fff;
          }
          &:nth-child(2n) {
            background: #f9f9f9;
          }
          &.checkout-btn {
            width: 100%;
            float: none;
            ul {
              padding: 0;
              margin: 20px 57px 20px 0;
              list-style: none;
              width: 20%;
              float: right;
              @media (max-width: 991px) {
                margin: 20px 0;
                width: 100%;
                padding: 0 15px;
              }
            }
            button {
              min-width: 308px;
              border-radius: 25px;
              font-size: 17px;
              font-weight: 600;
              height: 47px;
              background: var(--green);
              border: none;
            }
          }
          th { 
            background: var(--site-purple);
            color: #fff;
            text-transform: uppercase;
            padding: 15px 0;
            position: sticky;
            top: 0;
            z-index: 9;
            text-align: center !important; 
            font-weight: normal;
            font-family: var(--common-fontmd);
            &:nth-child(4) {
              min-width: 90px;
            }
            @media (max-width: 991px) {
              padding: 12px;
            }
          } 
          td {
            text-align: center;
            .main-qty-sec {
              margin: 13px  0px 15px;
              display: flex;
              justify-content: center;
              @media (max-width: 991px) {
                justify-content: center;
                margin: 0 auto;
              }
            }
            select {
              width: 155px;
              height: 40px;
              border: 1px solid #ddd;
              border-radius: 50px;
              background: transparent;
              padding: 0 10px;
            }
            #qty {
              @media (max-width: 991px) {
                justify-content: center;
              }
              input {
                background: #fff;
                width: 40px !important;
                border-right: 1px solid var(--green);
                border-left: 1px solid var(--green);
              }
              button {
                width: 40px;
                padding: 0;
                height: 40px;
                display: flex;
                justify-content: center;
              } 
            }

            .cart-product-qty-del button.dlt {
                  background: none;
              }
          }
        }

        .cart-product-details {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border-radius: 50px;
            width: 30px;
            background: #f3dcff;
            max-width: 30px;
            min-width: 30px;
            height: 30px;
            margin-left: 10px;
            font-size: 12px;
            color: var(--site-purple);
          }
        }
        .Add_to_cart button {
          font-size: 13px !important;
          font-weight: 600;
          border-radius: 50px;
        }
        .pro-cart-table {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .checkout_button {
          float: right;
          margin: 0 20px 0px 0;
          @media (max-width: 991px) {
            margin: 0;
          }
          button {
            min-width: 316px;
            height: 49px;
            border-radius: 25px; 
            font-size: 18px;
            background: var(--green);
            border: 2px solid var(--green);
            &:hover {
              background: #fff;
              color: var(--green);
            }
            @media (max-width: 767px) {
              min-width: 120px;
              height: 42px;
              font-size: 14px;
            }
          }
        }
      }
      @media (max-width: 991px) {
        overflow-x: auto;
      }
      @media (max-width: 767px) {
        overflow-x: visible;
        .checkout-btn td {
          padding-left: 10px !important;
        }
        .checkout-btn td:before {
          display: none !important;
        }
        tr.checkout-btn ul {
          margin: 10px 0;
          width: 100%;
        }
      }
    }
  }
  .mini-cart-sec
    .mini-cart
    .min-cat-uppper
    .mini-cart-des
    .main-qty-sec
    .box
    #qty {
    max-width: 80% !important;
    border: 0px solid #ddd;
    display: flex;
  }
  table.Cart_product.whistlist-table .main-qty-sec {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  table.Cart_product.whistlist-table .main-qty-sec .box {
    padding: 0;
    min-width: 120px;
  }

  /*Cart
https://ccmlm.com
*/
.main-qty-sec {
            .box {
              #qty {
                max-width: 100%;
                display: flex;
                justify-content: center;
                border: 1px solid var(--green);
                button {
                  background: #fff;
                  border-radius: 0;
                  width: 32px;display: flex;
                  font-size: 19px;
                  font-weight: 600;
                  align-items: center;
                  border: none;
                  color: var(--green);
                }
                input {
                  width: 40px;
                  border: none;
                  text-align: center;
                  background: #fff;font-family: var(--common-font);
                  border-left: 1px solid var(--green);
                  border-right: 1px solid var(--green);
                }
                .sub {
                  left: 0;
                }
                .add {
                  right: 0;
                }
              }
            }
          }
  @media (max-width: 1440px) {
    .main_viewCart_section_class .table_container .whistlist-table tr td #qty {
      display: flex !important;
    }
    .main_viewCart_section_class .main-qty-sec .box #qty button {
      height: 28px !important;
    }
  }
  @media (max-width: 1024px) {
    .main-qty-sec .box {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }

  @media (max-width: 480px) {
    table.Cart_product.whistlist-table th {
      min-width: 250px;
    }
  }
`;

const SecondTableCss = styled.div`
  margin-top: 40px;
  .heading_of_pack {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: var(--green);
    color: #fff;
    font-size: 15px;
    font-weight: 600;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
    .title_of_pack {
      padding: 15px;
      font-size: 18px;
      width: 100%;
    }
    .amount_and_edit {
      display: flex;
      flex-flow: nowrap;
      gap: 20px;
    }
    .button_for_edit {
      display: flex;
      gap: 20px;
      div {
        padding: 15px;
      }
    }
    .amount {
      padding: 18px;
      margin-right: 0;
    }
    @media (max-width: 767px) {
      h3.title {
        margin: 10px;
      }
    }
  }
  .table_container {
    margin-top: 10px;
    @media (max-width: 991px) {
      overflow-x: auto;
    }
    table {
      tr {
        th {
          text-align: center;
          padding: 15px 20px;
          background: var(--green);
          color: #fff;
        }
        td {
          padding: 15px 0px;
        }
      }
    }
  }
`;


const RelatedProductStyle = styled.div`
  .shipping>.row {
      margin: 0;
      border-top: 1px solid #ddd;
      padding: 10px;
      background: #fff;
  }
  .heading_section {
    margin: 20px auto;
    text-align: center;
    max-width: 600px;
    p{
      @media (max-width: 767px){
          line-height: normal;
          }
      }
    h2 {
        font-size: 24px;
        font-family: var(--inte-BD);
        margin-bottom: 0;
        line-height: normal;
        span {color: #f00;
        font-family: var(--inte-BD);} 
        @media (max-width: 767px){
        font-size: 18px;
        }
    }  
  }
  table {
    width: 100%;

    tr {
        &.border_table_row {
            border-top: 1px solid #eee;
        }
        th{
            background: var(--site-purple);
            color: #fff;
        }
        th,td {
            padding: 10px 0;
            text-align: center;
            font-family: var(--common-fontmd);
        }
        td {
            background:#fff;
            .Total {
                display: flex;
                justify-content: right;
                width: 100%;
                max-width: 340px !important;

                strong {
                    padding-right: 10px;
                    font-size: 16px;
                }
            }
            p {
                margin-bottom: 0px;
                font-size: 13px;
                line-height: 20px;
              }
            &.radio_section_div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .image {
                  width: 150px;
                  height: 150px;

                  img {
                    width: 100%;
                  }
                  
                }
                .realted_radio {
                    top:-23px;
                }
            }
            .main-qty-secc {
                padding: 0;
                margin: 0 auto;
                width: fit-content;

                #qty {
                    position: relative;
                    align-items: center;
                    border: 1px solid var(--green);
                    border-left: 0;
                    border-right: 0;
                    width: max-content;

                    button {
                        height: 40px;
                        color: #000;
                        border: 0;
                        padding: 0;
                        background: #fff;
                        min-width: 30px;
                        border-right: 1px solid var(--green);
                        border-left: 1px solid var(--green);
                        border-radius: 0;
                        margin: 0;
                    }
                    input {
                        width: 40px;
                        border: 0;
                        height: 37px;
                        text-align: center;
                        background: #fff;
                        padding-right: 0;
                    }
                }
            }
            &.cart-product-details {
                display: table-cell;
                .box {
                    margin: 0 auto;
                    select {
                        text-align: center;
                        width: 155px;
                        height: 40px;
                        border: 1px solid #ddd;
                        border-radius: 50px;
                        background: transparent;
                        padding: 0 10px;
                    }
                }
            }
              &.amount_strick div {
                &:first-child {
                    text-decoration: line-through;
                }
                &:last-child {
                    color: #f00;
                    font-family: var(--common-fontmd);
                }
            }
            &.add_to_cart button {
              padding: 10px 20px;
              border-radius: 30px;
              background: var(--green);
              color: #fff;
            }
        }
    }
}
        .related-products-btn[disabled]{
              background: grey;
            }
            select.form-select-product {
              border-radius: 50px;
              padding: 10px;
              display: block;
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .new-check-compo {
              align-items: center;

              @media only screen and (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
                gap: 15px;
              }
              .radio_section_div {
                padding-right: 15px;

                @media only screen and (max-width: 767px) {
                  padding-right: 0;
                }

                .image {
                  width: 100px;
                  height: 100px;
                  border: 2px solid #ddd;
                  border-radius: 10px;
                  background-color: #fff;
                  padding: 3px;

                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    display: block;
                  }
                }
              }
              .text_realted_css {
                width: calc(100% - 200px - 30px);
                font-weight: 600;
                @media only screen and (max-width: 767px) {
                  width: 100%;
                  text-align: center;
                  line-height: 1;
                }

                p {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: inherit;
                  margin: 5px 0 0;
                }
              }
              .amount_strick {
                padding-left: 15px;
                width: 115px;
                text-align: right;

                @media only screen and (max-width: 767px) {
                  padding-left: 0px;
                  width: 100%;
                  text-align: center;
                }

                div:not(:last-child) {
                  text-decoration: line-through;
                }
              }
            }
            .qty-subscription-data {
              margin: 20px 0;
              gap: 25px;

              @media only screen and (max-width: 767px) {
                justify-content: center;
                flex-wrap: wrap;
              }

              .cart-product-details {
                padding-right: 7px;

                .box {
                  .form-select {
                    cursor: pointer;
                  }
                }
              }

              .name_tableData {
                #qty {
                  border: 1px solid #ddd;
                  border-radius: 50px;
                  background-color: #fff;
                  display: flex;

                  button {
                    padding: 0;
                    margin: 0;
                    width: 30px;
                    background-color: transparent;
                  }

                  input {
                    padding: 0px;
                    border-radius: 0;
                    border: none;
                    border-left: 1px solid #ddd;
                    border-right: 1px solid #ddd;
                    width: 40px;
                    text-align: center;
                    height: 40px;
                  }
                }
              }
            }
            .add_to_cart {
              width: 100%;

              @media only screen and (max-width: 767px) {
                text-align: center;
              }

              .related-products-btn {
                margin: 0;
                border-radius: 50px;
                background-color: var(--green);
                color: #fff;
                transition: all ease-in-out 0.3s;
                max-width: 249px;
                width: 100%;

                @media only screen and (max-width: 767px) {
                  margin: auto;
                }

                &::before {
                  display: none;
                }

                &:hover {
                  background-color: #286090;
                }
              }
            }
            .amount_value{
              padding-left: 40px;
            }

`;
export default ViewCartStyle;
export { SecondTableCss, RelatedProductStyle };
