
import { addToCartMethod, isPercentageAssign } from '@Components/Common/CommonMethods';
import NumberFormatComp from '@Components/Common/NumberFormatComp';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

class RelatedProduct extends React.Component {
    constructor(porps) {
        super(porps);
        this.state = {
            selected: null,
            quantity: [{ id: null, qty: 1, maxQty: null }],
            taxPrice: null,
            shippingData: [],
            selectedShipping: { id: null, amount: null },
            frequancy_date: [{ id: null, days: "30" }],
            autoshipData: [{ id: null, autoship: "autoship" }],
            subtotal: null,
            total: null,
            percentage: null,
        }
        this.setCartNotify = this._setCartNotify.bind(this)
        // this.taxCalculate = this._texCalculationApi.bind(this);
    }

    componentDidMount() {
        // this.taxCalculate();
        // this.availableShippingModules();
        setTimeout(() => {
            this.setState({
                quantity: _.map(this.props?.relatedProduct, (row) => ({
                    id: row?.id, qty: this.props?.selectedProductFreq?.product_id === +row?.id ? +this.props?.selectedProductFreq?.quantity : 1, maxQty: row?.quantity
                }))
            });
            this.setState({ frequancy_date: _.map(this.props?.relatedProduct, (row) => ({ id: row?.id, days: "30" })) });
            this.setState({ autoshipData: _.map(this.props?.relatedProduct, (row) => ({ id: row?.id, autoship: "autoship" })) });
            this.setState({ percentage: isPercentageAssign() })
            if (this.props?.selectedProductFreq?.product_id) {
                this.setState({
                    selected: this.props?.selectedProductFreq?.product_id,
                    subtotal: this.props?.selectedProductFreq?.quantity * this.props?.selectedProductFreq?.amount,
                    total: this.props?.selectedProductFreq?.quantity * this.props?.selectedProductFreq?.amount
                })
            }

        }, 4000)
    }
    // _radioSelected(id) {
    //     this.props.setSelectedProductFreq({
    //         ...this.props.selectedProductFreq,
    //         product_id: id, quantity: _.find(this.state?.quantity, { id: id })?.qty,
    //         shipping_frequency: +(_.find(this.state.frequancy_date, { id: id })?.days)

    //     });
    //     this.setState({ selected: id });
    // }

    _setCartNotify(msg) {
        this.setState({ addToCartMsg: msg });
    }

    _addToCartApplied(id) {
        this.props.setSelectedProductFreq({
            ...this.props.selectedProductFreq,
            quantity: _.find(this.state.quantity, { id })?.qty,
            shipping_frequency: +(_.find(this.state.frequancy_date, { id })?.days),
            is_autoship: _.find(this.state.autoshipData, { id })?.autoship
        });

        addToCartMethod(
            {
                id: id,
                quantity: _.find(this.state.quantity, { id })?.qty,
                is_autoship: _.find(this.state.autoshipData, { id })?.autoship === "one-time" ? false : true,
            },
            this.props?.setshowminicart,
            this.setCartNotify,
            this.props?.setcoupon(''),
            this.props?.setAmountCalculations(),
            this.props?.setdiscount_amount(0)
        )

    }

    _onQtyfuntion(section, id) {
        // let OPTION = this.state.quantity;
        const QTY = _.find(this.state.quantity, { id: id });
        const MAX_QTY = QTY?.maxQty;
        const CURRENT_QTY = QTY?.qty;

        if (section === "add") {
            if (CURRENT_QTY < MAX_QTY) {
                QTY['qty'] = QTY?.qty + 1;
                const REJECTED_DATA = _.reject(this.state.quantity, QTY);
                this.setState({ quantity: [...REJECTED_DATA, QTY] });
                // this.props.setSelectedProductFreq({
                //     ...this.props.selectedProductFreq,
                //     quantity: _.find([...REJECTED_DATA, QTY], { id: this.state.selected })?.qty
                // });
            }
        } else {
            if (CURRENT_QTY !== 1) {
                QTY['qty'] = QTY?.qty - 1;
                const REJECTED_DATA = _.reject(this.state.quantity, { id: id });
                this.setState({ quantity: [...REJECTED_DATA, QTY] });
                // this.props.setSelectedProductFreq({
                //     ...this.props.selectedProductFreq,
                //     quantity: _.find([...REJECTED_DATA, QTY], { id: this.state.selected })?.qty
                // });
            }
        }
    }
    // async _texCalculationApi(token) {
    //     const FILTER_PRODUCT = _.filter(this.props?.relatedProduct, { id: this.state.selected });
    //     const PRODUCT_DATA_ARRAY = _.map(FILTER_PRODUCT, (row, index) => {
    //         const totalAmount = row?.autoship_cost_price * (_.find(this.state.quantity, { id: row?.id })?.qty);
    //         this.setState({ subtotal: totalAmount, total: totalAmount });
    //         return ({
    //             amount: parseFloat(row?.autoship_cost_price),
    //             sku: row?.sku,
    //             quantity: _.find(this.state.quantity, { id: row?.id })?.qty,
    //             number: index + 1,
    //             taxcode: row?.tax_code
    //         })
    //     });

    //     const GET_ADDRESS_DETAILS = _.find(this.props?.addressData?.addresses, { id: +this.props?.shippingaddress });
    //     if (this.state.selected !== null) {
    //         await api.calculateTaxAmount({
    //             "city": (!GET_ADDRESS_DETAILS) ? this.props?.methods.getValues('shipping_city') : GET_ADDRESS_DETAILS?.city,
    //             "country": (!GET_ADDRESS_DETAILS) ? this.props?.shippingCountry : GET_ADDRESS_DETAILS?.country,
    //             "line1": (!GET_ADDRESS_DETAILS) ? this.props?.methods.getValues('shipping_street_address_1') : GET_ADDRESS_DETAILS?.street_address_1,
    //             "postalCode": (!GET_ADDRESS_DETAILS) ? this.props?.methods.getValues('shipping_postal_code') : GET_ADDRESS_DETAILS?.postal_code,
    //             "region": (!GET_ADDRESS_DETAILS) ? this.props?.shippingState : GET_ADDRESS_DETAILS?.state,
    //             "products": PRODUCT_DATA_ARRAY
    //         }).then((res) => {
    //             if (res.status === 200) {
    //                 if (res?.data?.code === 1) {
    //                     this.setState({ taxPrice: res?.data?.totalTax, total: this.state?.subtotal + res?.data?.totalTax });
    //                     this.props.setSelectedProductFreq({
    //                         ...this.props.selectedProductFreq, tax_amount: res?.data?.totalTax,
    //                         amount: FILTER_PRODUCT?.length === 1 ? FILTER_PRODUCT[0]?.autoship_cost_price : "0.00",
    //                     });
    //                 }
    //             }
    //         }).catch((err) => {
    //             throw new Error('error comming from RelatedProducts', err)
    //         })
    //     }
    // }
    // componentDidUpdate(prevProps, prvState) {
    //     if (prevProps?.shippingState !== this.props?.shippingState ||
    //         prevProps?.shippingaddress !== this.props?.shippingaddress ||
    //         prevProps?.stateFieldData !== this.props?.stateFieldData ||
    //         prvState.selected !== this.state.selected ||
    //         prvState.quantity !== this.state.quantity) {

    //         this.availableShippingModules()
    //         this.taxCalculate();
    //     }
    // }

    // async availableShippingModules() {
    //     const PRODUCT_DATA = _.filter(this.props?.relatedProduct, { id: this.state.selected });
    //     const QTY = _.find(this.state?.quantity, { id: this.state.selected })?.qty;


    //     const payload = {
    //         // shipping_address_id: parseInt(+SHIPPING_ADDRESS),
    //         shipping_address_id: this.props?.shippingaddress ? this.props?.shippingaddress : null,
    //         cart_items: PRODUCT_DATA?.length > 0 ? PRODUCT_DATA?.length * QTY : 0,
    //         cart_weight: PRODUCT_DATA?.length > 0 ? PRODUCT_DATA[0]?.weight * QTY : 0,
    //         cart_total: PRODUCT_DATA?.length > 0 ? PRODUCT_DATA[0]?.autoship_cost_price * QTY : 0,
    //     };

    //     let checkState = true;
    //     if (this.props?.shippingaddress === undefined || !this.props?.shippingaddress) {
    //         checkState = this.props?.shippingState ? true : false;

    //         // !promoterToken ?

    //         payload['shipping_address_detail'] = {
    //             "first_name": "",
    //             "last_name": "",
    //             "company_name": "",
    //             "phone_number": "",
    //             "city": "",
    //             "postal_code": "",
    //             "street_address_1": "",
    //             "country": this.props?.shippingCountry || 'US',
    //             "state": this?.props?.shippingState,
    //             "street_address_2": ""
    //         };
    //     }
    //     const Token = (this.props?.section === "kitCheckout") ? null : localStorage.getItem("Token");
    //     if (PRODUCT_DATA?.length > 0 && checkState) {
    //         await api.availableShippingModules(Token, payload).then((response) => {
    //             if (response.status === 200 && response?.data) {
    //                 let data = [];
    //                 Object.entries(response?.data).forEach(([key, value]) => {
    //                     if (key !== "code") { data = [...data, ...value]; }
    //                 });
    //                 this.setState({ shippingData: _.filter(data, { for_autoship: true }) })
    //             }
    //         }).catch((err) => console.log(err));
    //     }
    // };

    // _handleClick(id, shipping_cost) {
    //     this.props.setShowContinue(id);
    //     this.setState({ selectedShipping: { id, amount: +shipping_cost } });
    //     this.props.setSelectedProductFreq({ ...this.props.selectedProductFreq, shipping_module_id: id, shipping_amount: +shipping_cost });
    // }

    _setAutoshipFunction(event, id) {
        const FIND_VALUE = _.find(this.state.autoshipData, { id: id });
        if (FIND_VALUE) {
            FIND_VALUE['autoship'] = event.target.value;
            const REMAIN_DATA = _.reject(this.state.autoshipData, FIND_VALUE);
            this.setState({ autoshipData: [...REMAIN_DATA, FIND_VALUE] });
            // this.props.setSelectedProductFreq({
            //     ...this.props.selectedProductFreq,
            //     shipping_frequency: +(_.find([...REMAIN_DATA, FIND_VALUE], { id: this.state.selected })?.days),
            // });
        }
    }

    _setShippingFrequency(event, id) {
        const FIND_VALUE = _.find(this.state.frequancy_date, { id: id });
        if (FIND_VALUE) {
            FIND_VALUE['days'] = event.target.value;
            const REMAIN_DATA = _.reject(this.state.frequancy_date, FIND_VALUE);
            this.setState({ frequancy_date: [...REMAIN_DATA, FIND_VALUE] });
            // this.props.setSelectedProductFreq({
            //     ...this.props.selectedProductFreq,
            //     shipping_frequency: +(_.find([...REMAIN_DATA, FIND_VALUE], { id: this.state.selected })?.days),
            // });
        }
    }

    render() {
        const { selectedProductFreq, relatedProduct } = this.props;
        const CUSTOM_PRODUCTS = this?.props?.cartdata?.products?.filter((item) => item?.product?.is_custom_product === "True");
        const Avaible_products = this?.props?.cartdata?.products?.filter((item) => item?.product?.sku === "KIT-SURVEY");
        const ancestry_product_data = this?.props?.cartdata?.products?.filter((item) => item?.product?.sku === "KIT-SURVEY-ANCESTRY")

        const DATA = localStorage.getItem('profileData') ? JSON.parse(localStorage.getItem('profileData')) : "";
        const AUTOSHIPUSER = DATA?.user_data?.userdetails[0]?.is_autoship_user

        return (
            <div className="shipping">
                <>
                    <div className='heading_section'>
                        <h2>Sign up for your customized CODE Complex™ supplement subscription and <span>SAVE up to $30 off</span></h2>

                    </div>
                    <div className='cart-row'>
                        <table className='Cart_product'>
                            <thead>
                                <tr>
                                    <th className='pro-name'>Product Image</th>
                                    <th className='pro-name'>Product Name</th>
                                    {(!(this.props?.subscribeAlready) && !(this.props?.smartShipOnCart)) &&
                                        <th className='pro-name'>Frequency</th>
                                    }
                                    {/* <th className='pro-name'>Shipping Frequency</th> */}
                                    <th className='pro-name'>Quantity</th>
                                    <th className='pro-name'>Price</th>
                                    <th className='pro-name'>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderRadius: "30px" }}>
                                {_.map(relatedProduct, (row, index) => {
                                    const AUTOSHIP_VALUE = _.find(this.state.autoshipData, { id: +row?.id })?.autoship;
                                    let AUTOSHIP_PRICE = this.state.percentage ? row?.autoship_cost_price - row?.autoship_cost_price * (this.state.percentage / 100) : row?.autoship_cost_price;
                                    let COST_PRICE = this.state.percentage ? row?.cost_price - row?.cost_price * (this.state.percentage / 100) : row?.cost_price;
                                    let REAL_PRICE = this.state.percentage ? row?.real_amount - row?.real_amount * (this.state.percentage / 100) : row?.real_amount;
                                    return (
                                        <tr key={row?.id}>
                                            <td className='radio_section_div' style={{ display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
                                                {/* <div className='radio'>
                                                <input type="radio" name="product_radio" checked={this.state.selected === row?.id} className='realted_radio' onChange={() => this._radioSelected(row?.id)} />
                                            </div> */}
                                                <h2 className='title'>
                                                    {row?.name?.includes('Capsule') ? 'Capsule' : 'Powder'}
                                                </h2>
                                                <div className='image'>
                                                    <img src={process.env.API_URL + row?.image} />
                                                </div>
                                            </td>
                                            <td className='text_realted_css'>
                                                {row?.name}

                                            </td>
                                            {(!(this.props?.subscribeAlready) && !(this.props?.smartShipOnCart)) &&
                                                <td className="cart-product-details" >
                                                    <div className="box">
                                                        <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                                                            value={AUTOSHIP_VALUE} onChange={(e) => this._setAutoshipFunction(e, row?.id)}
                                                        >
                                                            <option value="one-time">One-Time</option>
                                                            <option value="autoship">Subscription</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            }
                                            <td className="name_tableData" >
                                                <div className="main-qty-secc">
                                                    <div id="qty">
                                                        <button type="button" className="sub" onClick={() => this._onQtyfuntion('sub', row?.id)}>-</button>
                                                        <input name="1" readOnly type="text" defaultValue={_.find(this.state.quantity, { id: row?.id })?.qty} />
                                                        <button type="button" className="add" onClick={() => this._onQtyfuntion('add', row?.id)}>+</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={(AUTOSHIP_VALUE === "one-time") ? 'amount_value' : 'amount_strick'}>
                                                {!AUTOSHIPUSER ? <NumberFormatComp value={COST_PRICE} />
                                                    :
                                                    <NumberFormatComp value={REAL_PRICE} />
                                                }

                                                {(AUTOSHIP_VALUE === "autoship" && !(this.props?.smartShipOnCart)) &&
                                                    <NumberFormatComp className="final-price" value={AUTOSHIP_PRICE} />
                                                }
                                            </td>
                                            <td className='add_to_cart' >
                                                <button type="button" className='related-products-btn' title={CUSTOM_PRODUCTS?.length === 1 ? "You can add only one custom product" : ""}
                                                    disabled={CUSTOM_PRODUCTS?.length === 1}
                                                    onClick={() => this._addToCartApplied(row?.id)}>Add to Cart</button>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
                {/* <div className='row'>
                    <div className='col-md-6'>
                        <div className="main_shipping_method_class">
                            <div className='labelSection'>
                                <div className="expand-row"> Choose a Shipping method: </div>
                                {this.state?.shippingData?.length > 0 && (
                                    <InputLabel style={{ fontSize: "16px" }} htmlFor="filled-age-native-simple" className="choose_label" required></InputLabel>
                                )}
                            </div>
                            <div className="radio_section_for_shipping_cost_gsn_related">
                                {this.state?.shippingData?.length > 0 ? (
                                    this.state?.shippingData?.map((data, index) => {
                                        return (
                                            <>
                                                <FormControl key={index + 1} className="radio_option">
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Radio
                                                                name="radioo"
                                                                value={data?.shipping_cost}
                                                                checked={this.state?.selectedShipping?.id === data?.id}
                                                                onChange={() => this._handleClick(data?.id, data?.shipping_cost)}
                                                                inputProps={{ "aria-label": "A" }}
                                                            />
                                                        }
                                                        label={<p>{`${data?.name}: ${data?.shipping_cost}`}</p>}
                                                    />
                                                </FormControl>
                                            </>

                                        );
                                    })) : (<span> No shipping method on this address.</span>)}
                            </div>
                            {this.props?.autoshipShippingError && !(selectedProductFreq?.shipping_amount) && (<span className="error">{this.props?.autoshipShippingError}</span>)}
                        </div>
                    </div>
                    <div className="col-md-6">
                        {(this.state.selected) &&
                            <span className="Total">
                                <strong>Sub-total:</strong>
                                <NumberFormatComp value={this.state.subtotal} />
                            </span>
                        }
                        {(this.state.selectedShipping?.amount || this.state.selectedShipping?.amount === 0) &&
                            <span className="Total">
                                <strong>Shipping:</strong>
                                <NumberFormatComp value={this.state.selectedShipping?.amount} />
                            </span>
                        }
                        {(this.state.taxPrice || this.state.taxPrice === 0) &&
                            <span className="Total">
                                <strong>Tax:</strong>
                                <NumberFormatComp value={this.state.taxPrice} />
                            </span>
                        }
                        {(this.state.selected) &&
                            <span className="Total">
                                <strong>Total:</strong>
                                <NumberFormatComp value={this.state.total + this.state.selectedShipping?.amount} />
                            </span>
                        }
                    </div>
                </div> */}


            </div>
        )
    }

};

export default RelatedProduct;