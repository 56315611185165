import styled from "styled-components";

const MinCartStyle = styled.div`
  z-index: 9999;
  position: relative;
  && .mini-cart-sec {
    .cart-heading {
      position: relative;
      h1 {
        margin: 0;
      }
      button {
        position: absolute;
        left: 0px;
        top: 0px;
        background: transparent;
        border: 0;
        color: #fff;
        font-weight: bold;
        background: #fff;
        color: var(--green);
        width: 30px;
        height: 100%;
        font-size: 15px;
        border-radius: 0;
        padding: 0;
        /* box-shadow: 0 0 0 #000, 0 0 16px #000; */
        /* border-bottom: 1px solid #ddd; */
      }
    }
    .Cart_product{
    .checkout-btn {
      button.btn.btn-primary { 
        display: table;
        float: none;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        color: #fff;
        max-width: fit-content;
        width: 100%;
        border-radius: 25px;
        border: 2px solid var(--green);
        transition: 0.3s ease all;
        background-color: var(--green);
        padding: 8px 30px;
        font-size: 16px;
        transition: 0.3s ease all;  
        &:hover{
          background-color: #fff;
          color: var(--green);
        }
    } 
  }
}

    .mini-cart { 
      .min-cat-uppper {
        .cart-image {
          img {
            mix-blend-mode: multiply;
          }
        }
        .mini-cart-des {
          .cart-product-details {
            .box {
              width: 100%;
              .select {
                display: table;
                width: 100%;
              }
            }

            :first-child {
              font-weight: 400;
              font-family: var(--common-font);
              color: var(--green);
              padding-right: 30px;
            }
          }
          .main-qty-sec {
            .box {
              #qty {
                max-width: 80% !important;
                display: flex;
                justify-content: center;
                border: 1px solid var(--green);
                button {
                  background: #fff;
                  border-radius: 0;
                  width: 32px;display: flex;
                  font-size: 19px;
                  font-weight: 600;
                  align-items: center;
                  border: none;
                }
                input {
                  width: 40px;
                  border: none;
                  text-align: center;
                  background: #fff;font-family: var(--common-font);
                  border-left: 1px solid var(--green);
                  border-right: 1px solid var(--green);
                }
                .sub {
                  left: 0;
                }
                .add {
                  right: 0;
                }
              }
            }
          }
        }
      }
      .cart-product-qty-del {
        // bottom: 32px!important;
        svg {
          font-size: 26px;
        }
        button { 
          border-radius: 0px;
          font-size: 10px;
          font-weight: bold;
          line-height: 1; 
          justify-content: center;
          border: 1px solid red;
          padding: 10px; 
          border: none;
          &:before {
          }
          svg {
            width: 20px;
            height: 20px;
            fill: red;
          }
        }
      } 
    }

    .checkout-btn {
      padding-bottom: 10px;
      @media(max-width:767px){
        padding-bottom: 40px;
      }
      .col-md-12{
        padding: 0;
      }
      .d-flex.btn-flex {
        width: 100%;
      button.btn.btn-primary {
        width: 100%;
        border-radius: 50px;
        font-size: 14px;
        background: var(--green);
        border: 2px solid var(--green);
        height: 44px;
        &:hover {
          background: #fff;
          color: var(--green);
        }
        
      }
      }
    }
   
  }
`;

export default MinCartStyle;
